<template>
  <div>
    <div class="header main">
      <div class="header_left">
        <img height="100%" src="@/assets/image/logo.png" alt="" />
      </div>
      <div class="header_right d-none d-sm-block">
        <el-menu
          router
          :default-active="$route.path"
          active-text-color="#fff"
          text-color="#fff"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item index="/homepage">首页</el-menu-item>
          <el-menu-item index="/productLine">产品线</el-menu-item>
          <el-menu-item index="/application">应用软件</el-menu-item>
          <el-menu-item index="/industry">行业方案</el-menu-item>
          <el-menu-item index="/my">联系我们</el-menu-item>
        </el-menu>
      </div>
      <!-- 弹框 -->
      <div class="header_right d-sm-none">
        <div class="dropdown">
          <div id="dropdownMenuButton1" data-bs-toggle="dropdown">
            <img src="@/assets/image/Banner/c6.png" alt="" />
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <!-- <li class="dropdown_li"></li> -->
            <el-menu
              router
              :default-active="$route.path"
              active-text-color="##0D36CE"
              text-color="##333333 "
              class="el-menu-demo"
            >
              <el-menu-item index="/homepage">首页</el-menu-item>
              <el-menu-item index="/productLine">产品线</el-menu-item>
              <el-menu-item index="/application">应用软件</el-menu-item>
              <el-menu-item index="/industry">行业方案</el-menu-item>
              <el-menu-item index="/my">联系我们</el-menu-item>
            </el-menu>
          </ul>
        </div>
      </div>
    </div>
    <!-- 轮播start -->
    <div class="banner d-none d-sm-block">
      <el-carousel height="600px">
        <el-carousel-item>
          <img class="ba_im" src="@/assets/image/mybner.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="banner d-sm-none">
      <el-carousel height="274px">
        <el-carousel-item>
           <div class="banner_add_ph">
            <div>期待为您服务</div>
            <div>
              <div class="d-flex">
              <div><img src="@/assets/image/Banner/a2.png" alt=""></div>
              <div class="add_ph_tit">近十年解决方案经验</div>
              </div>
               <div class="d-flex">
              <div><img src="@/assets/image/Banner/a2.png" alt=""></div>
              <div class="add_ph_tit">专注的领域经验</div>
              </div>
               <div class="d-flex">
              <div><img src="@/assets/image/Banner/a2.png" alt=""></div>
              <div class="add_ph_tit">近50人研发经验</div>
              </div>
               <div class="d-flex">
              <div><img src="@/assets/image/Banner/a2.png" alt=""></div>
              <div class="add_ph_tit">全球大客户服务经验</div>
              </div>
            </div>
           </div>
          <img class="ba_im" src="@/assets/image/Banner/c5.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播end -->

    <!-- footer -->
    <div class="footer_fot_box d-none d-sm-block">
      <div class="footer_fot d-flex main">
        <div class="footer-lin2">
          <div class="footer_min_tit">联系我们</div>
          <div class="lin_ft"></div>
          <div class="mg_bom d-flex">
            <div class="mg_ri">
              <img src="@/assets/image/g (1).png" alt="" />
            </div>
            <div>电话 : (+86)-xxxx-85582181</div>
          </div>
          <div class="mg_bom d-flex">
            <div class="mg_ri">
              <img src="@/assets/image/g (4).png" alt="" />
            </div>
            <div>邮件 : support@i4season.com</div>
          </div>
          <div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (5).png" alt="" />
              </div>
              <div>地址 : 深圳市龙华区民治街道樟坑一区青创城A栋2-A8</div>
            </div>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长沙市高新技术开发区麓谷企业广场F1栋24楼
            </div>
          </div>
        </div>
        <div class="footer-lin3">
          <div class="footer_min_tit">
            业务经理
            <div class="lin_ft2"></div>
          </div>

          <div class="mg_bom d-flex">
            <div class="mg_ri">
              <img src="@/assets/image/g (2).png" alt="" />
            </div>
            <div>钱经理 : 158 9236 8127</div>
          </div>
          <div>
            <img src="@/assets/image/wechat.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="footer_fot_box d-sm-none">
      <div class="footer_fot">
        <div class="footer-lin3 d-flex">
        <div>
            <div class="footer_min_tit add_footer_min_tit">
            业务经理
          </div>

          <div class="mg_bom d-flex">
            <div class="mg_ri">
              <img src="@/assets/image/g (2).png" alt="" />
            </div>
            <div>钱经理 : 158 9236 8127</div>
          </div>
        </div>

           <div>
            <img src="@/assets/image/wechat.png" alt="" />
          </div>
        </div>
        <div class="footer-lin2">
          <div class="footer_min_tit">联系我们</div>
          <div class="lin_ft"></div>
          <div class="mg_bom d-flex">
            <div class="mg_ri">
              <img src="@/assets/image/g (1).png" alt="" />
            </div>
            <div>电话 : (+86)-xxxx-85582181</div>
          </div>
          <div class="mg_bom d-flex">
            <div class="mg_ri">
              <img src="@/assets/image/g (4).png" alt="" />
            </div>
            <div>邮件 : support@i4season.com</div>
          </div>
          <div>
            <div class="mg_bom d-flex">
              <div class="mg_ri">
                <img src="@/assets/image/g (5).png" alt="" />
              </div>
              <div>地址 : 深圳市龙华区民治街道樟坑一区青创城A栋2-A8</div>
            </div>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;长沙市高新技术开发区麓谷企业广场F1栋24楼
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
// 标题
._tit {
  margin-bottom: 4px;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Regular;
  color: #333333;
  font-weight: 400;
}
._tit2 {
  text-align: center;
  font-family: SFPro-Bold;
  font-size: 24px;
  color: #dddddd;
  font-weight: 700;
  line-height: 30px;
}
._lin {
  width: 80px;
  border-bottom: 4px solid #4066f5;
  display: flex;
  justify-content: center;
  margin: 24px auto;
}

// header start
.el-menu {
  background: rgba($color: #000000, $alpha: 0);
}
/deep/.el-submenu__title:hover,
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: rgba($color: #000000, $alpha: 0) !important;
}
.el-menu.el-menu--horizontal {
  border: none;
}
/deep/.el-carousel__arrow--left {
  display: none !important;
}
/deep/.el-carousel__arrow--right {
  display: none !important;
}
.header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  display: flex;
  // border-bottom:2px solid #fff ;
}

.header_left {
  position: absolute;
  top: 16px;
}
.header_right {
  position: relative;
  left: 66%;
  top: 16px;
}
// header end

// 轮播start
.banner {
  max-width: 1920px;
  margin: 0 auto;
}
.ba_im {
  max-width: 1920px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.el-carousel__indicators--horizontal {
  display: none;
}

.banner_add_ph {
  position: absolute;
  z-index: 999;
  left: 58%;
  top: 34%;
  color: #fff;
}
.add_ph_tit {
  font-size: 12px;
  transform: scale(0.7);
  transform-origin: 0 0;
  // white-space: nowrap;
  margin-top: 8px;
  margin-left: 4px;
}
// 轮播end

// footer start
.footer {
  // height: 300px;
  // background: #4066f5;
  background: url("../../assets/image/her1.png") no-repeat center;
  color: #fff;
  font-family: PingFangSC-Medium;
  padding-bottom: 24px;
}
.main_one {
  height: 226px;
  padding: 10px 180px 40px 170px;
  margin-right: 160px;
  border-right: 1px solid #ccc;
}
.footer_main {
  display: flex;
  justify-content: space-between;
}
.footer_tit {
  height: 60px;
  font-size: 45px;
  font-family: FZLTXHK--GBK1-0;

  color: #ffffff;
  letter-spacing: 4px;
}
.footer_min_tit {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-left: 12px;
  // border-bottom:2px solid #fff ;
}
.footer_min_tit2 {
  font-size: 65px;
  font-family: FZLTXHK--GBK1-0;
  line-height: 0px;
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 46px;
}
.lin_ft {
  width: 80px;
  border-bottom: 4px solid #4066f5;
  margin: 24px 0;
  margin-left: 12px;
}
.lin_ft2 {
  width: 80px;
  border-bottom: 4px solid #4066f5;
  margin: 24px 0;
}
.mg_bom {
  margin-bottom: 12px;
}
.footer-ft {
  margin-top: 10px;
}
.mg_ri {
  margin-right: 8px;
  margin-top: -2px;
}
.footer_cen {
  height: 430px;
  align-items: center;
}
.ft {
  font-size: 20px;
}
// footer end
.footer-lin3 {
  margin-left: 240px;
}
.footer_fot_box {
  background: #fff;
  padding-top: 30px;
}
// 手机
@media only screen and (max-width: 760px) {
  // tou
  .header {
    width: calc(100vw);
    display: flex;
    justify-content: space-between;
  }
  .header_left {
    height: 28px;
    margin-left: 18px;
    margin-top: -2px;
  }
  .header_right {
    margin-left: 92px;
  }
  .dropdown {
    margin-bottom: 20px;
  }
  .dropdown-menu {
    width: calc(100vw);
    height: calc(100vh);
    background: #fff;
  }
  // banner
  .banner {
    width: calc(100vw);
  }
  .ba_im {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  // footer
  .footer_min_tit{
    padding: 0;
  }
    .footer_fot {
    width: calc(100vw);
    padding: 0 20px;
  }
  .footer-lin3 {
    margin-left: 0px;
  }
  .footer-lin1 {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .footer_min_tit{
    font-size: 16px;
    margin-bottom:4px ;
    margin-left: 0;
  }
  .add_footer_min_tit{
     margin-bottom:14px ;
  }
  .footer-lin3 {
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 12px;
  }
   .footer-lin2 {
    font-size: 12px;
  }
  .lin_ft {
   border-bottom: 2px solid #4066f5;
   margin: 10px 0 10px 0;
  }
  .footer-ft {
    padding: 26px 0;
  }
  .cen {
    text-align: center;
  }
}
</style>
